<template>
    <div class="myaccount">
      <loader v-if="!userInfo.id"/>
      <div class="show">
        <p id="showmessage">Your Profile has been updated.</p>
      </div>
      <div class="main" v-if="userInfo.id">
        <div class="section">
            <h4>Update Account Information</h4>
            <form v-on:submit="userUpdate()" class="inner">
                <div class="fields">
                    <div class="field">
                        <label for="">First Name</label>
                        <input type="text" name="" required v-model="userInfo.first_name">
                    </div>
                </div>
                <div class="fields">
                    <div class="field">
                        <label for="">Last Name</label>
                        <input type="text" name="" required v-model="userInfo.last_name">
                    </div>
                </div>
                <div class="fields">
                    <div class="field">
                        <label>Email</label>
                        <input type="email" v-model="userInfo.email" required disabled>
                    </div>
                </div>
                <div class="fields">
                    <div class="field">
                        <label for="">Phone No</label>
                        <input type="number" name="" required v-model="userInfo.phone">
                    </div>
                </div>
                <div class="fields">
                    <div class="field">
                        <label for="">Address</label>
                        <textarea name="" cols="10" rows="2" required v-model="userInfo.address"></textarea>
                    </div>
                </div>
                <div class="fields">
                    <div class="field">
                        <label for="">Password</label>
                        <input type="password" name="" required v-model="userInfo.password">
                    </div>
                </div>
                <label v-if="error" for="" style="color:red">This Email is Already Registered</label>
                <button v-if="!userInfo.id" type="submit" class="btnlogin">Sign Up</button>
                <button v-if="userInfo.id" type="submit" id="btnlogin">Update</button>
            </form>
        </div>
        <!-- <div v-if="userInfo.id" class="section1">
              <h4>Customers orders</h4>
              <table>
                <tr>
                    <th>Image</th>
                    <th>Order id</th>
                    <th>Price</th>
                    <th>Status</th>
                </tr>
                    <div v-if="orders && orders.hits && orders.hits.length == 0" style="width: 100%;margin: 19% 1% 0 60%;">
                        No Orders Submitted yet
                    </div>
                    <tr v-else v-for="(items, ind) in orders" :key="ind">
                        <td style="width:50px; height:60px"><img :src="$store.state.file_url + items.cart[0].images[0].image[0].src" alt="" style="width:50px;object-fit:cover"></td>
                        <td>{{items.id}}</td>
                        <td v-if="currency == 'PKR'">{{currency}} {{items.total}}/-</td>
                        <td v-if="currency == 'USD'">{{currency}} {{convertCurrency(items.total)}}/-</td>
                        <td>{{items.status}}</td>
                    </tr>
                
              </table>
        </div> -->
    </div>
    <Signin id="signinContainer"/>
    <Signup id="signupContainer"/>
  </div>

</template>

<script>
import axios from "axios";
export default {
  components: {
    Signin: () => import("@/components/Signin.vue"),
    Signup: () => import("@/components/Signup.vue"),
    loader: () => import("../components/loader.vue")
  },
  data() {
    return {
      userInfo: {},
      orders: {},
      error: false,
      currency: ""
    };
  },
  mounted() {
    this.init();
  },
  created() {
    setTimeout(() => {
      this.init();
      if (localStorage.getItem("currency")) {
        this.currency = JSON.parse(localStorage.getItem("currency"));
      }
    }, 500);
  },
  methods: {
    init() {
      this.$store.dispatch("Store");
      this.userInfo = this.$store.state.customer;
      this.orderRead();
    },
    userUpdate(e) {
      event.preventDefault();
      document.getElementById("btnlogin").disabled = true;
      document.querySelector('#btnlogin').innerHTML = 'Updating....';
      axios
        .post(this.$store.state.api + "users/_update/" + this.userInfo.id, {
          doc: this.userInfo
        })
        .then(response => {
          var x = document.getElementById("showmessage");
          x.className = "active";
          document.getElementById("btnlogin").disabled = false;
          document.querySelector('#btnlogin').innerHTML = 'Update';
          // console.log(response, "updateded");
          localStorage.setItem("users", JSON.stringify(this.userInfo));
          setTimeout(function(){ 
                x.className = x.className.replace("active", ""); 
          }, 3000);
          // alert(done);
        });
    },
    orderRead() {
      this.$store
        .dispatch("OrderRead", {
          bool: {
            should: [],
            must: [
              {
                match_phrase: {
                  event: this.$store.state.event
                }
              },
              {
                match_phrase: {
                  "customer.email": this.userInfo.email
                }
              }
            ]
          }
        })
        .then(response => {
          this.orders = response;
          // this.orders.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0));
          // console.log(this.orders);
        });
    },
    convertCurrency(amount) {
      var price = false;
      var c = false;
      if (localStorage.getItem("currencyValue")) {
        c = JSON.parse(localStorage.getItem("currencyValue"));
      }
      price = Math.round(amount / c);
      return price;
    }
  }
};
</script>
<style scoped>

.show{
  width: 100%;
  text-align: center;
}

    

#showmessage{
    background: #d2e4c4;
    width: 40%;
    color: #307a07;
    padding: 10px 20px;
    font-size: 14px;
    display: none;
    margin-top: 10px;
    margin: 10px auto 0;
}


.active {
   /* transform: translateY(-100px) !important; */
   display: flex !important;
}
</style>